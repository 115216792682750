import { db } from '@/firebaseConfig'
import { generateUUID } from '@/utils'
import type firebase from 'firebase/compat/app'
import {
  collection,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  type DocumentData,
  addDoc,
  type WhereFilterOp,
  setDoc,
  CollectionReference,
  orderBy,
  limit,
  startAfter
} from 'firebase/firestore'

class FirebaseService {
  /**
   * Lấy danh sách tài liệu từ một collection.
   * @param collectionName Tên của collection Firebase.
   * @returns Mảng các tài liệu.
   * Ví dụ:  const projects = await FirebaseService.getCollection('projects')
   */
  async getCollection(collectionName: string): Promise<any[]> {
    const querySnapshot = await getDocs(collection(db, collectionName))
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  }

  /**
   * Lấy danh sách tài liệu từ một collection với phân trang.
   * @param collectionName Tên của collection cần lấy.
   * @param currentPage Trang hiện tại.
   * @param itemsPerPage Số mục trên mỗi trang.
   * @param orderByField Trường dùng để sắp xếp.
   * @returns Mảng các tài liệu từ collection thỏa mãn phân trang.
   */
  async getPaginatedCollection(
    collectionName: string,
    currentPage: number = 1,
    itemsPerPage: number = 10,
    orderByField: string = 'create_time'
  ): Promise<{ items: any[]; total: number }> {
    const collectionRef = collection(db, collectionName)
    const totalSnapshot = await getDocs(collectionRef)
    const totalDocs = totalSnapshot.size
    const totalPages = Math.ceil(totalDocs / itemsPerPage)
    // Ensure the current page is within bounds
    currentPage = Math.max(1, Math.min(currentPage, totalPages))
    const offset = (currentPage - 1) * itemsPerPage
    // Fetch the required documents for the current page
    const q = query(collectionRef, orderBy(orderByField), limit(offset + itemsPerPage))
    const querySnapshot = await getDocs(q)
    const allDocs = querySnapshot.docs
    const items = allDocs
      .slice(offset, offset + itemsPerPage)
      .map((doc) => ({ id: doc.id, ...doc.data() }))
    return {
      items,
      total: totalDocs
    }
  }

  /**
   * Lấy danh sách tài liệu từ một collection với điều kiện và phân trang.
   * @param collectionName Tên của collection cần lấy.
   * @param field Tên trường cần áp dụng điều kiện.
   * @param operator Toán tử so sánh (ví dụ: '==', '>', '>=', '<', '<=').
   * @param value Giá trị để so sánh.
   * @param currentPage Trang hiện tại.
   * @param itemsPerPage Số mục trên mỗi trang.
   * @param orderByField Trường dùng để sắp xếp.
   * @returns Mảng các tài liệu từ collection thỏa mãn điều kiện và phân trang.
   */
  async getPaginatedCollectionWithWhere(
    collectionName: string,
    field: string,
    operator: firebase.firestore.WhereFilterOp,
    value: any,
    currentPage: number = 1,
    itemsPerPage: number = 10,
    orderByField: string = 'create_time'
  ): Promise<{ items: any[]; total: number }> {
    const collectionRef = collection(db, collectionName)
    const conditionQuery = query(
      collectionRef,
      where(field, operator, value),
      where(field, '<=', value + '\uf8ff')
    )
    // Đếm tổng số lượng tài liệu phù hợp với điều kiện
    const totalSnapshot = await getDocs(conditionQuery)
    const totalDocs = totalSnapshot.size
    const totalPages = Math.ceil(totalDocs / itemsPerPage)
    // Ensure the current page is within bounds
    currentPage = Math.max(1, Math.min(currentPage, totalPages))
    const offset = (currentPage - 1) * itemsPerPage
    // Fetch the required documents for the current page
    const paginatedQuery = query(
      conditionQuery,
      orderBy(orderByField),
      limit(offset + itemsPerPage)
    )
    const querySnapshot = await getDocs(paginatedQuery)
    const allDocs = querySnapshot.docs
    const items = allDocs
      .slice(offset, offset + itemsPerPage)
      .map((doc) => ({ id: doc.id, ...doc.data() }))
    return {
      items,
      total: totalDocs
    }
  }

  /**
   * Lấy tài liệu từ collection theo ID.
   * @param collectionName Tên của collection Firebase.
   * @param docId ID của tài liệu cần lấy.
   * @returns Tài liệu được lấy nếu tồn tại, hoặc null nếu không tồn tại.
   * Ví dụ:
   * const projectId = 'abc123' // ID của dự án cần lấy
   * const project = await FirebaseService.getDocumentById('projects', projectId)
   */
  async getDocumentById(collectionName: string, docId: string): Promise<any | null> {
    const docRef = doc(db, collectionName, docId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() }
    } else {
      return null
    }
  }

  /**
   * Lấy danh sách tài liệu từ collection theo điều kiện.
   * @param collectionName Tên của collection Firebase.
   * @param field Tên trường để áp dụng điều kiện.
   * @param operator Toán tử so sánh (ví dụ: '==', '>', '<').
   * @param value Giá trị để so sánh.
   * @returns Mảng các tài liệu thỏa điều kiện.
   * Ví dụ: const mobileProjects = await FirebaseService.getDocumentsWhere('projects', 'category', '==', 'mobile')
   */
  async getDocumentsWhere(
    collectionName: string,
    field: string,
    operator: WhereFilterOp,
    value: any
  ): Promise<any[]> {
    const q = query(collection(db, collectionName), where(field, operator, value))
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  }

  /**
   * Cập nhật tài liệu trong collection.
   * @param collectionName Tên của collection Firebase.
   * @param docId ID của tài liệu cần cập nhật.
   * @param data Dữ liệu mới cần cập nhật.
   * Ví dụ:
   * const projectToUpdate = {
   *  name: 'Dự án A',
   *  category: 'web',
   *  description: 'Mô tả của dự án A'
   *  // Thêm các trường dữ liệu cần cập nhật
   * }
   * const projectIdToUpdate = 'xyz789' // ID của dự án cần cập nhật
   * await FirebaseService.updateDocument('projects', projectIdToUpdate, projectToUpdate)
   */
  async updateDocument(collectionName: string, docId: string, data: any): Promise<void> {
    const docRef = doc(db, collectionName, docId)
    await updateDoc(docRef, data)
  }

  /**
   * Xóa một tài liệu trong collection.
   * @param collectionName Tên của collection Firebase.
   * @param docId ID của tài liệu cần xóa.
   * Ví dụ:
   * await FirebaseService.deleteDocument('projects', 'abc123')
   */
  async deleteDocument(collectionName: string, docId: string): Promise<void> {
    const docRef = doc(db, collectionName, docId)
    await deleteDoc(docRef)
  }

  /**
   * Thêm một tài liệu mới vào collection.
   * @param collectionName Tên của collection Firebase.
   * @param data Dữ liệu của tài liệu mới.
   * @returns ID của tài liệu mới được thêm vào.
   * Ví dụ:
   * const newProjectData = { name: 'New Project', category: 'web', description: 'Description of new project' }
   * const newProjectId = await FirebaseService.addDocument('projects', newProjectData)
   */
  async addDocument(collectionName: string, data: any): Promise<string | null> {
    const docRef = await addDoc(collection(db, collectionName), data as DocumentData)
    return docRef.id
  }

  /**
   * Thêm một tài liệu mới vào collection với ID tùy chỉnh.
   * @param collectionName Tên của collection Firebase.
   * @param data Dữ liệu của tài liệu mới.
   * @param customId ID tùy chỉnh cho tài liệu mới.
   * @returns ID của tài liệu mới được thêm vào.
   * Ví dụ:
   * const newProjectData = { name: 'New Project', category: 'web', description: 'Description of new project' }
   * const newProjectId = await FirebaseService.addDocument('projects', newProjectData, 'customDocId')
   */
  async addDocumentWithId(
    collectionName: string,
    data: any,
    customId: string
  ): Promise<string | null> {
    try {
      const docRef = doc(collection(db, collectionName), customId)
      await setDoc(docRef, data)
      return docRef.id
    } catch (error) {
      console.error('Error adding document: ', error)
      return null
    }
  }

  // Child Collection
  // Phương thức lấy CollectionReference của child collection
  getChildCollectionRef(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string
  ): CollectionReference {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    return collection(parentDocRef, childCollectionName)
  }

  /**
   * Lấy danh sách tài liệu từ một child collection của một tài liệu cha với phân trang.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection cần lấy.
   * @param currentPage Trang hiện tại.
   * @param itemsPerPage Số mục trên mỗi trang.
   * @returns Mảng các tài liệu từ child collection.
   * Ví dụ: const { items, total } = await FirebaseService.getPaginatedCollection('projects', 'abc123', 'comments', 1, 10)
   */
  async getPaginatedChildCollection(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    currentPage: number,
    itemsPerPage: number,
    orderByField: string = 'create_time'
  ): Promise<{ items: any[]; total: number }> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childCollectionRef = collection(parentDocRef, childCollectionName)
    const commentsQuery = query(
      childCollectionRef,
      orderBy(orderByField),
      limit(itemsPerPage),
      ...(currentPage > 1 ? [startAfter((currentPage - 1) * itemsPerPage)] : [])
    )
    const querySnapshot = await getDocs(commentsQuery)
    const totalSnapshot = await getDocs(childCollectionRef)
    return {
      items: querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      total: totalSnapshot.size
    }
  }

  /**
   * Lấy danh sách tài liệu từ child collection theo điều kiện.
   * @param parentCollectionName Tên của collection chính.
   * @param parentDocId ID của tài liệu chính.
   * @param childCollectionName Tên của child collection.
   * @param field Tên trường để áp dụng điều kiện.
   * @param operator Toán tử so sánh (ví dụ: '==', '>', '<').
   * @param value Giá trị để so sánh.
   * @returns Mảng các tài liệu thỏa điều kiện.
   * Ví dụ: const mobileTasks = await FirebaseService.getChildDocumentsWhere('projects', 'projectId', 'tasks', 'status', '==', 'completed')
   */
  async getChildDocumentsWhere(
    parentCollectionName: string,
    parentDocId: string,
    childCollectionName: string,
    field: string,
    operator: WhereFilterOp,
    value: any
  ): Promise<any[]> {
    const childCollectionRef = collection(
      db,
      parentCollectionName,
      parentDocId,
      childCollectionName
    )
    const q = query(childCollectionRef, where(field, operator, value))
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  }

  /**
   * Lấy danh sách tài liệu từ một child collection của một tài liệu cha với phân trang và điều kiện.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection cần lấy.
   * @param currentPage Trang hiện tại.
   * @param itemsPerPage Số mục trên mỗi trang.
   * @param field Tên trường để áp dụng điều kiện.
   * @param operator Toán tử so sánh (ví dụ: '==', '>', '<').
   * @param value Giá trị để so sánh.
   * @param orderByField Trường để sắp xếp.
   * @returns Mảng các tài liệu từ child collection.
   * Ví dụ: const { items, total } = await FirebaseService.getPaginatedCollection('projects', 'abc123', 'comments', 1, 10, 'status', '==', 'approved')
   */
  async getPaginatedChildCollectionWithWhere(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    currentPage: number,
    itemsPerPage: number,
    field: string,
    operator: WhereFilterOp,
    value: any,
    orderByField: string = 'create_time'
  ): Promise<{ items: any[]; total: number }> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childCollectionRef = collection(parentDocRef, childCollectionName)
    let baseQuery = query(
      childCollectionRef,
      where(field, operator, value),
      orderBy(orderByField),
      limit(itemsPerPage)
    )
    if (currentPage > 1) {
      const previousPageSnapshot = await getDocs(
        query(
          childCollectionRef,
          where(field, operator, value),
          orderBy(orderByField),
          limit((currentPage - 1) * itemsPerPage)
        )
      )
      const lastVisible = previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1]
      baseQuery = query(baseQuery, startAfter(lastVisible))
    }

    const querySnapshot = await getDocs(baseQuery)
    const totalSnapshot = await getDocs(query(childCollectionRef, where(field, operator, value)))

    return {
      items: querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      total: totalSnapshot.size
    }
  }

  /**
   * Lấy danh sách tài liệu từ một child collection của một tài liệu cha.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection cần lấy.
   * @returns Mảng các tài liệu từ child collection.
   * Ví dụ: const comments = await FirebaseService.getChildCollection('projects', 'abc123', 'comments')
   */
  async getChildCollection(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string
  ): Promise<any[]> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childCollectionRef = collection(parentDocRef, childCollectionName)
    const querySnapshot = await getDocs(childCollectionRef)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  }

  /**
   * Thêm một tài liệu mới vào child collection của một tài liệu cha.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection cần thêm vào.
   * @param data Dữ liệu của tài liệu mới.
   * @returns ID của tài liệu mới được thêm vào child collection.
   * Ví dụ:
   * const newCommentData = { user: 'UserA', content: 'Comment content' }
   * const newCommentId = await FirebaseService.addChildDocument('projects', 'abc123', 'comments', newCommentData)
   */
  async addChildDocument(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    data: any
  ): Promise<string | null> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childCollectionRef = collection(parentDocRef, childCollectionName)
    const docRef = await addDoc(childCollectionRef, data as DocumentData)
    return docRef.id
  }

  /**
   * Cập nhật một tài liệu trong child collection của một tài liệu cha.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection chứa tài liệu cần cập nhật.
   * @param docId ID của tài liệu cần cập nhật trong child collection.
   * @param data Dữ liệu mới cần cập nhật.
   * Ví dụ:
   * const updatedData = { content: 'Updated content' }
   * await FirebaseService.updateChildDocument('projects', 'abc123', 'comments', 'xyz789', updatedData)
   */
  async updateChildDocument(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    docId: string,
    data: any
  ): Promise<void> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childDocRef = doc(parentDocRef, childCollectionName, docId)
    await updateDoc(childDocRef, data)
  }

  /**
   * Xóa một tài liệu trong child collection của một tài liệu cha.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection chứa tài liệu cần xóa.
   * @param docId ID của tài liệu cần xóa trong child collection.
   * Ví dụ:
   * await FirebaseService.deleteChildDocument('projects', 'abc123', 'comments', 'xyz789')
   */
  async deleteChildDocument(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    docId: string
  ): Promise<void> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childDocRef = doc(parentDocRef, childCollectionName, docId)
    await deleteDoc(childDocRef)
  }

  /**
   * Lấy chi tiết của một tài liệu trong child collection của một tài liệu cha.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection chứa tài liệu cần lấy.
   * @param docId ID của tài liệu trong child collection cần lấy chi tiết.
   * @returns Dữ liệu của tài liệu trong child collection.
   * Ví dụ:
   * const commentDetail = await FirebaseService.getChildDocument('projects', 'abc123', 'comments', 'xyz789')
   */
  async getChildDocumentById(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    docId: string
  ): Promise<any | null> {
    try {
      const parentDocRef = doc(db, parentCollectionName, parentId)
      const childDocRef = doc(parentDocRef, childCollectionName, docId)
      const docSnap = await getDoc(childDocRef)
      if (docSnap.exists()) {
        const data = { id: docSnap.id, ...docSnap.data() }
        return data
      } else {
        console.error('No such document!')
        return null
      }
    } catch (error) {
      console.error('Error getting child document:', error)
      throw error
    }
  }

  /**
   * Sao chép một tài liệu và toàn bộ child collections của tài liệu đó.
   * @param collectionName Tên của collection chứa tài liệu gốc.
   * @param originalDocId ID của tài liệu gốc.
   * @param newDocId ID của tài liệu mới.
   * @param childCollectionNames Tên của các child collections.
   * Ví dụ:
   * await FirebaseService.cloneDocumentWithChildren('projects', 'oldDocId', 'newDocId', ['comments', 'tasks'])
   */
  async cloneDocumentWithChildren(
    collectionName: string,
    originalDocId: string,
    newDocId: string,
    childCollectionNames: string[]
  ): Promise<void> {
    // Tham chiếu đến tài liệu gốc
    const originalDocRef = doc(db, collectionName, originalDocId)
    // Tham chiếu đến tài liệu mới
    const newDocRef = doc(db, collectionName, newDocId)
    try {
      // Đọc dữ liệu từ tài liệu gốc
      const originalDocSnap = await getDoc(originalDocRef)
      if (originalDocSnap.exists()) {
        // Lấy dữ liệu từ tài liệu gốc
        const data = { ...originalDocSnap.data(), id: newDocId }
        // Ghi dữ liệu vào tài liệu mới
        await setDoc(newDocRef, data)
        // Sao chép các tài liệu trong từng child collection
        for (const childCollectionName of childCollectionNames) {
          const childDocsSnap = await this.getChildCollection(
            collectionName,
            originalDocId,
            childCollectionName
          )
          for (const childDoc of childDocsSnap) {
            await this.addChildDocument(collectionName, newDocId, childCollectionName, childDoc)
          }
        }
      } else {
        console.log('No such document!')
      }
    } catch (error) {
      console.error('Error cloning document: ', error)
    }
  }

  /**
   * Sao chép một tài liệu và toàn bộ child collections của tài liệu đó.
   * @param collectionName Tên của collection chứa tài liệu gốc.
   * @param originalDocId ID của tài liệu gốc.
   * @param newDocId ID của tài liệu mới.
   * @param childCollectionName Tên của các child collections.
   * @param generateNewIdForChildDocs Có tạo ID mới cho child documents hay không.
   * Ví dụ:
   * await FirebaseService.copyChildCollection('projects', 'oldDocId', 'newDocId', 'comments', true)
   */
  async copyChildCollection(
    collectionName: string,
    originalDocId: string,
    newDocId: string,
    childCollectionName: string,
    generateNewIdForChildDocs: boolean = false
  ): Promise<void> {
    const originalDocRef = doc(db, collectionName, originalDocId)
    const newDocRef = doc(db, collectionName, newDocId)
    try {
      const originalDocSnap = await getDoc(originalDocRef)
      if (originalDocSnap.exists()) {
        const data = { ...originalDocSnap.data(), id: newDocId }
        await setDoc(newDocRef, data)
        const childCollectionRef = collection(
          db,
          collectionName,
          originalDocId,
          childCollectionName
        )
        const childDocsSnap = await getDocs(childCollectionRef)
        for (const childDoc of childDocsSnap.docs) {
          const childData = childDoc.data()
          const newChildDocId = generateNewIdForChildDocs ? generateUUID() : childDoc.id
          const newChildDocRef = doc(
            db,
            collectionName,
            newDocId,
            childCollectionName,
            newChildDocId
          )
          await setDoc(newChildDocRef, childData)
        }
      } else {
        console.log('No such document!')
      }
    } catch (error) {
      console.error('Error copying child collection: ', error)
    }
  }

  // Phương thức lấy CollectionReference của grandchild collection
  getGrandChildCollectionRef(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    childId: string,
    grandChildCollectionName: string
  ): CollectionReference {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childDocRef = doc(parentDocRef, childCollectionName, childId)
    return collection(childDocRef, grandChildCollectionName)
  }

  /**
   * Lấy danh sách tài liệu từ child collection của child collection.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection cần lấy.
   * @param grandChildCollectionName Tên của grandchild collection cần lấy.
   * @returns Mảng các tài liệu từ grandchild collection.
   */
  async getGrandChildCollection(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    childId: string,
    grandChildCollectionName: string
  ): Promise<any[]> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childDocRef = doc(parentDocRef, childCollectionName, childId)
    const grandChildCollectionRef = collection(childDocRef, grandChildCollectionName)
    const querySnapshot = await getDocs(grandChildCollectionRef)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  }

  /**
   * Thêm một tài liệu mới vào grandchild collection của một tài liệu con.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection chứa tài liệu con.
   * @param childId ID của tài liệu con.
   * @param grandChildCollectionName Tên của grandchild collection cần thêm vào.
   * @param data Dữ liệu của tài liệu mới.
   * @returns ID của tài liệu mới được thêm vào grandchild collection.
   */
  async addGrandChildDocument(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    childId: string,
    grandChildCollectionName: string,
    data: any
  ): Promise<string | null> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childDocRef = doc(parentDocRef, childCollectionName, childId)
    const grandChildCollectionRef = collection(childDocRef, grandChildCollectionName)
    const docRef = await addDoc(grandChildCollectionRef, data as DocumentData)
    return docRef.id
  }

  /**
   * Xóa một tài liệu trong grandchild collection của một tài liệu con.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection chứa tài liệu con.
   * @param childId ID của tài liệu con.
   * @param grandChildCollectionName Tên của grandchild collection chứa tài liệu cần xóa.
   * @param docId ID của tài liệu cần xóa trong grandchild collection.
   */
  async deleteGrandChildDocument(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    childId: string,
    grandChildCollectionName: string,
    docId: string
  ): Promise<void> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childDocRef = doc(parentDocRef, childCollectionName, childId)
    const grandChildDocRef = doc(childDocRef, grandChildCollectionName, docId)
    await deleteDoc(grandChildDocRef)
  }

  /**
   * Cập nhật một tài liệu trong grandchild collection của một tài liệu con.
   * @param parentCollectionName Tên của collection chứa tài liệu cha.
   * @param parentId ID của tài liệu cha.
   * @param childCollectionName Tên của child collection chứa tài liệu con.
   * @param childId ID của tài liệu con.
   * @param grandChildCollectionName Tên của grandchild collection chứa tài liệu cần cập nhật.
   * @param docId ID của tài liệu cần cập nhật trong grandchild collection.
   * @param data Dữ liệu mới cần cập nhật.
   */
  async updateGrandChildDocument(
    parentCollectionName: string,
    parentId: string,
    childCollectionName: string,
    childId: string,
    grandChildCollectionName: string,
    docId: string,
    data: any
  ): Promise<void> {
    const parentDocRef = doc(db, parentCollectionName, parentId)
    const childDocRef = doc(parentDocRef, childCollectionName, childId)
    const grandChildDocRef = doc(childDocRef, grandChildCollectionName, docId)
    await updateDoc(grandChildDocRef, data)
  }
}

export default new FirebaseService()
