import { collection, getDocs, query, where, getDocsFromCache, onSnapshot } from 'firebase/firestore'
import { db, doc, updateDoc, getDoc } from '@/firebaseConfig'

export async function getProjectList() {
  // const projectCollection = collection(db, 'project')
  const querySnapshot = await getDocs(collection(db, 'project'))
  const documents = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data()
  }))
  return documents
  // // Try to get data from cache first
  // try {
  //   const cacheQuerySnapshot = await getDocsFromCache(projectCollection)
  //   if (!cacheQuerySnapshot.empty) {
  //     const documents = cacheQuerySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data()
  //     }))
  //     // Return the cached documents immediately
  //     return documents
  //   }
  // } catch (cacheError) {
  //   console.error('Error getting documents from cache:', cacheError)
  //   // If there's an error getting documents from cache, proceed to fetch from server
  // }
  //
  // // If cache is empty or there was an error, fetch data from server
  // return new Promise((resolve, reject) => {
  //   const unsubscribe = onSnapshot(
  //     projectCollection,
  //     (serverQuerySnapshot) => {
  //       const documents = serverQuerySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data()
  //       }))
  //       console.log('Updated documents from server:', documents)
  //       unsubscribe() // Unsubscribe after getting the latest data
  //       resolve(documents)
  //     },
  //     (error) => {
  //       console.error('Error getting documents from server:', error)
  //       reject(error)
  //     }
  //   )
  // })
}

export async function getDocumentById(docId) {
  const docRef = doc(db, 'project', docId)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return { ...docSnap.data() }
  } else {
    console.log('No such document!')
  }
}

export async function getProjectWhereCategory(docId, category = 'category') {
  const querySnapshot = await getDocs(collection(db, `project/${docId}/${category}`))
  const documents = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data()
  }))
  return documents
}

export async function getProjectWhereApartment(docId, category = 'apartment') {
  const querySnapshot = await getDocs(collection(db, `project/${docId}/${category}`))
  const documents = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data()
  }))
  return documents
}

export async function getProjectWhereComment(docId, comment = 'comment') {
  const querySnapshot = await getDocs(collection(db, `project/${docId}/${comment}`))
  const documents = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data()
  }))
  return documents
}

export async function getDocumentByIdWhereCategory(docId, type) {
  const q = query(collection(db, `project/${docId}/category`), where('type', '==', 'perspective'))
  const querySnapshot = await getDocs(q)
  const documents = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data()
  }))
  return documents
}

export async function getFileUrl(filePath: string) {
  try {
    const fileRef = storage.ref().child(filePath)
    const fileUrl = await fileRef.getDownloadURL()
    console.log('File URL:', fileUrl)
    return fileUrl
  } catch (error) {
    console.error('Error getting download URL:', error)
  }
}

export async function updateProject(project: any) {
  const todoRef = doc(db, 'project', project.id)
  await updateDoc(todoRef, {
    isLoved: project.isLoved,
    statistics: {
      ...project.statistics,
      digg_count: project.isLoved
        ? project.statistics.digg_count + 1
        : project.statistics.digg_count - 1
    }
  })
}
