import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import {
  initializeFirestore,
  doc,
  updateDoc,
  getDoc,
  CACHE_SIZE_UNLIMITED,
  persistentLocalCache
} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBY1WEZGcf0h4_fAThO3rtDxIGzztoTQHo',
  authDomain: 'noble-app-dev.firebaseapp.com',
  projectId: 'noble-app-dev',
  storageBucket: 'noble-app-dev.appspot.com',
  messagingSenderId: '982434778075',
  appId: '1:982434778075:web:149c28bd6cd58569d375fe'
}
//
// const firebaseConfig = {
//   apiKey: 'AIzaSyAKzjyHvvo1qWEk_Q2EAK7ApU9vOf2xuGI',
//   authDomain: 'noble-app-production.firebaseapp.com',
//   projectId: 'noble-app-production',
//   storageBucket: 'noble-app-production.appspot.com',
//   messagingSenderId: '484142951912',
//   appId: '1:484142951912:web:48b8c135ca37a16483885d',
//   measurementId: 'G-E719K9E02G'
// }

const firebaseApp = initializeApp(firebaseConfig)

const db = initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache({
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  })
})

const storage = getStorage(firebaseApp)
const authFire = getAuth(firebaseApp)
export { firebaseApp, db, doc, updateDoc, getDoc, authFire, storage }
