export const COLLECTION_NAMES = {
  PROJECT: 'project',
  TANO: 'tano',
  CATEGORY: 'category',
  UTILITY: 'utility',
  COMMENT: 'comment',
  REPLIES: 'replies',
  APARTMENT: 'apartment'
}

export const AUTHENS = {
  AUTHEN_DATA: 'tt_authen_data'
}
