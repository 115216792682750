<script setup lang="ts">
import { inject, reactive } from 'vue'
import { useClick } from '@/utils/hooks/useClick'
const props = defineProps({
  isMy: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  isLive: {
    type: Boolean,
    default: () => {
      return false
    }
  }
})
const vClick = useClick()
const item = inject<any>('item')
const state = reactive({
  isAttention: false,
  test: [1, 2]
})
</script>
<template>
  <div class="item-desc mb1r" style="z-index: 9">
    <div class="content" v-if="!props.isMy">
      <!--      <div class="location-wrapper" v-if="item.city || item.address">-->
      <!--        <div class="location d-flex middle">-->
      <!--          <span class="icon">-->
      <!--            <svg-->
      <!--              width="12"-->
      <!--              height="14"-->
      <!--              viewBox="0 0 12 14"-->
      <!--              fill="none"-->
      <!--              xmlns="http://www.w3.org/2000/svg"-->
      <!--            >-->
      <!--              <path-->
      <!--                d="M6 0C4.54182 0.00165421 3.14383 0.581648 2.11274 1.61274C1.08165 2.64383 0.501654 4.04182 0.5 5.5C0.5 10.2063 5.5 13.7606 5.71313 13.9094C5.7972 13.9683 5.89735 13.9999 6 13.9999C6.10265 13.9999 6.2028 13.9683 6.28687 13.9094C6.5 13.7606 11.5 10.2063 11.5 5.5C11.4983 4.04182 10.9184 2.64383 9.88726 1.61274C8.85617 0.581648 7.45818 0.00165421 6 0ZM6 3.5C6.39556 3.5 6.78224 3.6173 7.11114 3.83706C7.44004 4.05682 7.69638 4.36918 7.84776 4.73463C7.99913 5.10009 8.03874 5.50222 7.96157 5.89018C7.8844 6.27814 7.69392 6.63451 7.41421 6.91421C7.13451 7.19392 6.77814 7.3844 6.39018 7.46157C6.00222 7.53874 5.60009 7.49913 5.23463 7.34776C4.86918 7.19638 4.55682 6.94004 4.33706 6.61114C4.1173 6.28224 4 5.89556 4 5.5C4 4.96957 4.21071 4.46086 4.58579 4.08579C4.96086 3.71071 5.46957 3.5 6 3.5Z"-->
      <!--                fill="#279643"-->
      <!--              />-->
      <!--            </svg>-->
      <!--          </span>-->
      <!--          <span>{{ item.city }}</span>-->
      <!--          <template v-if="item.address">-->
      <!--            <div class="gang"></div>-->
      <!--          </template>-->
      <!--          <span>{{ item.address }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="author" style="display: inline-block">
        <div class="d-flex middle">
          <span class="name" v-if="item.author">{{ item.author.nickname }}</span>
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2001_4784)">
              <path
                d="M6.89276 11.9281C10.1669 11.9281 12.8212 9.27386 12.8212 5.99969C12.8212 2.72552 10.1669 0.0712891 6.89276 0.0712891C3.61859 0.0712891 0.964355 2.72552 0.964355 5.99969C0.964355 9.27386 3.61859 11.9281 6.89276 11.9281Z"
                fill="#20D5EC"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.134 3.99342C10.4234 4.28283 10.4234 4.75201 10.134 5.04141L6.79926 8.37614C6.50985 8.66554 6.04067 8.66554 5.75126 8.37614L3.65162 6.2765C3.36222 5.98709 3.36222 5.51791 3.65162 5.2285C3.94103 4.9391 4.41021 4.9391 4.69961 5.2285L6.27526 6.80415L9.08599 3.99342C9.37539 3.70402 9.84458 3.70402 10.134 3.99342Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2001_4784">
                <rect
                  width="11.8568"
                  height="11.8568"
                  fill="white"
                  transform="translate(0.964355 0.0712891)"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            width="3"
            height="3"
            viewBox="0 0 3 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.78516 2.51562C1.25195 2.51562 0.853516 2.11133 0.853516 1.58984C0.853516 1.06836 1.25195 0.664062 1.78516 0.664062C2.32422 0.664062 2.7168 1.06836 2.7168 1.58984C2.7168 2.11133 2.32422 2.51562 1.78516 2.51562Z"
              fill="#8D8D8D"
            />
          </svg>
          <span class="by">Tác giả</span>
        </div>
      </div>
      <!--      <div class="price">-->
      <!--        <div class="d-flex middle">-->
      <!--          257,000,000 đ-->
      <!--          <svg-->
      <!--            width="16"-->
      <!--            height="16"-->
      <!--            viewBox="0 0 16 16"-->
      <!--            fill="none"-->
      <!--            xmlns="http://www.w3.org/2000/svg"-->
      <!--          >-->
      <!--            <path-->
      <!--              d="M13.0306 9.53063L8.5306 14.0306C8.46092 14.1005 8.37813 14.156 8.28696 14.1939C8.1958 14.2317 8.09806 14.2512 7.99935 14.2512C7.90064 14.2512 7.8029 14.2317 7.71173 14.1939C7.62057 14.156 7.53778 14.1005 7.4681 14.0306L2.9681 9.53063C2.8272 9.38973 2.74805 9.19863 2.74805 8.99938C2.74805 8.80012 2.8272 8.60902 2.9681 8.46813C3.10899 8.32723 3.30009 8.24807 3.49935 8.24807C3.69861 8.24807 3.8897 8.32723 4.0306 8.46813L7.24997 11.6875V2.5C7.24997 2.30109 7.32899 2.11032 7.46964 1.96967C7.61029 1.82902 7.80106 1.75 7.99997 1.75C8.19889 1.75 8.38965 1.82902 8.5303 1.96967C8.67095 2.11032 8.74997 2.30109 8.74997 2.5V11.6875L11.9693 8.4675C12.1102 8.3266 12.3013 8.24745 12.5006 8.24745C12.6999 8.24745 12.891 8.3266 13.0318 8.4675C13.1727 8.6084 13.2519 8.79949 13.2519 8.99875C13.2519 9.19801 13.1727 9.3891 13.0318 9.53L13.0306 9.53063Z"-->
      <!--              fill="#46A758"-->
      <!--            />-->
      <!--          </svg>-->
      <!--          <span class="regular-price">-->
      <!--            <del>100,000,000 đ</del>-->
      <!--          </span>-->
      <!--        </div>-->
      <!--        <div class="price-per">Est. 40,000,000 đ/m2</div>-->
      <!--      </div>-->
      <div class="product-info">
        <!--        <div class="name">Biệt thự A1 -D1</div>-->
        <div class="place">{{ item.desc }}</div>
        <div class="tag" v-if="item.hashtag">{{ item.hashtag }}</div>
      </div>
      <!-- <div class="live" v-if="props.isLive">直播中</div> -->
      <!-- <div class="name mb1r f18 fb" @click.stop="$emit('goUserInfo')">
        @{{ item.author.nickname }}
      </div> -->
      <!-- <div class="description">
        {{ item.desc }}
      </div> -->
      <!--      <div class="music" @click.stop="bus.emit('nav','/home/music')">-->
      <!--        <img src="../../assets/img/icon/music.svg" alt="" class="music-image">-->
      <!--        <span>{{ item.music.title }}</span>-->
      <!--      </div>-->
    </div>
    <div v-else class="comment-status">
      <div class="comment">
        <div class="type-comment">
          <img src="../../assets/img/icon/head-image.jpeg" alt="" class="avatar" />
          <div class="right">
            <p>
              <span class="name">zzzzz</span>
              <span class="time">2020-01-20</span>
            </p>
            <p class="text">北京</p>
          </div>
        </div>
        <transition-group name="comment-status" tag="div" class="loveds">
          <div class="type-loved" :key="i" v-for="i in state.test">
            <img src="../../assets/img/icon/head-image.jpeg" alt="" class="avatar" />
            <img src="../../assets/img/icon/love.svg" alt="" class="loved" />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.item-desc {
  position: absolute;
  bottom: 0;
  width: 70%;

  .content {
    color: #fff;
    text-align: left;

    .location-wrapper {
      display: flex;
    }

    .live {
      border-radius: 3rem;
      margin-bottom: 10rem;
      padding: 3rem 6rem;
      font-size: 11rem;
      display: inline-flex;
      background: var(--primary-btn-color);
      color: white;
    }

    .music {
      position: relative;
      display: flex;
      align-items: center;

      .music-image {
        width: 20px;
        height: 20px;
      }
    }
  }

  .comment-status {
    display: flex;
    align-items: center;

    .comment {
      .type-comment {
        display: flex;
        background: rgb(130, 21, 44);
        border-radius: 50px;
        padding: 3px;
        margin-bottom: 20px;

        .avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }

        .right {
          margin: 0 10px;
          color: var(--second-text-color);

          .name {
            margin-right: 10px;
          }

          .text {
            color: white;
          }
        }
      }

      .loveds {
      }

      .type-loved {
        width: 40px;
        height: 40px;
        position: relative;
        margin-bottom: 20px;
        animation: test 1s;
        animation-delay: 0.5s;

        .avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }

        .loved {
          position: absolute;
          bottom: 0;
          left: 20px;
          width: 10px;
          height: 10px;
          background: red;
          padding: 3px;
          border-radius: 50%;
          border: 2px solid white;
        }
      }

      @keyframes test {
        from {
          display: block;
          transform: translate3d(0, 0, 0);
        }
        to {
          display: none;
          transform: translate3d(0, -60px, 0);
        }
      }
    }
  }
}
</style>
