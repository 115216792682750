import { defineStore } from 'pinia'
import { friends, panel } from '@/api/user'
import enums from '@/utils/enums'
import resource from '@/assets/data/resource'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebaseConfig'
import { getDocumentById, getProjectWhereCategory } from '@/api/project'
import { marked } from 'marked'
import { cloneDeep } from '@/utils'

export const useProductStore = defineStore('product', {
  state: () => {
    return {
      todos: [],
      project: {}
    }
  },
  getters: {
    async selectFriends() {
      const querySnapshot = await getDocs(collection(db, 'project'))
      const documents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))

      return documents
    }
  },
  actions: {
    async init({ key }) {
      const res: any = await getDocumentById(key)
      const category: any = await getProjectWhereCategory(key, 'category')
      const utility: any = await getProjectWhereCategory(key, 'utility')
      const perspective = category.filter((item) => item.type === 'perspective')
      const interior = category.filter((item) => item.type === 'interior')
      const tvc = category.filter((item) => item.type === 'tvc')
      const utilityOut = utility.filter((item) => item.type === 'out')
      const utilityIn = utility.filter((item) => item.type === 'in')
      // const project = category.filter((item) => item.type === 'project')
      // const video = category.filter((item) => item.type === 'video')
      // const l360 = category.filter((item) => item.type === '360')
      this.project = {
        ...res,
        full_desc: marked(res.full_desc),
        lPerspectives: perspective,
        lInteriors: interior,
        lTvc: tvc.length > 0 ? tvc[0] : null,
        lUtilityOut: utilityOut.length > 0 ? utilityOut[0] : null,
        lUtilityIn: utilityIn.length > 0 ? utilityIn[0] : null,
        categorys: category
        // lProjects: project,
        // lVideos: video,
        // l360s: l360
      }
    }
  }
})

export const useRouteStore = defineStore('route', {
  state: () => ({
    routeData: {
      list: [],
      index: 0
    }
  }),
  actions: {
    setRouteData(data: any) {
      this.routeData = cloneDeep(data)
    }
  }
})

export const useBaseStore = defineStore('base', {
  state: () => {
    return {
      bodyHeight: document.body.clientHeight,
      bodyWidth: document.body.clientWidth,
      maskDialog: false,
      maskDialogMode: 'dark',
      version: '17.1.0',
      excludeNames: [],
      judgeValue: 20,
      homeRefresh: 60,
      loading: false,
      routeData: null,
      users: [],
      products: [],
      userinfo: {
        nickname: '',
        desc: '',
        user_age: '',
        signature: '',
        unique_id: '',
        province: '',
        city: '',
        gender: '',
        school: {
          name: '',
          department: null,
          joinTime: null,
          education: null,
          displayType: enums.DISPLAY_TYPE.ALL
        },
        avatar_168x168: {
          url_list: []
        },
        avatar_300x300: {
          url_list: []
        },
        cover_url: [
          {
            url_list: []
          }
        ],
        white_cover_url: [
          {
            url_list: []
          }
        ]
      },
      friends: resource.users
    }
  },
  getters: {
    selectFriends() {
      return this.friends.all.filter((v) => v.select)
    }
    // async getProjectList() {
    //   const querySnapshot = await getDocs(collection(db, 'project'))
    //   const documents = querySnapshot.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data()
    //   }))
    //
    //   return documents
    // }
  },
  actions: {
    async init() {
      const r = await panel()
      if (r.success) {
        this.userinfo = Object.assign(this.userinfo, r.data)
      }
      const r2 = await friends()
      if (r2.success) {
        this.users = r2.data
      }
    },
    setUserinfo(val) {
      this.userinfo = val
    },
    setMaskDialog(val) {
      this.maskDialog = val.state
      if (val.mode) {
        this.maskDialogMode = val.mode
      }
    },
    updateExcludeNames(val) {
      if (val.type === 'add') {
        if (!this.excludeNames.find((v) => v === val.value)) {
          this.excludeNames.push(val.value)
        }
      } else {
        const resIndex = this.excludeNames.findIndex((v) => v === val.value)
        if (resIndex !== -1) {
          this.excludeNames.splice(resIndex, 1)
        }
      }
      // console.log('store.excludeNames', store.excludeNames,val)
    }
  }
})

//
// export const useBaseStore = defineStore('base', {
//   state: () => {
//     return {
//       bodyHeight: document.body.clientHeight,
//       bodyWidth: document.body.clientWidth,
//       maskDialog: false,
//       maskDialogMode: 'dark',
//       version: '17.1.0',
//       excludeNames: [],
//       judgeValue: 20,
//       homeRefresh: 60,
//       loading: false,
//       routeData: null,
//       users: [],
//       userinfo: {
//         nickname: '',
//         desc: '',
//         user_age: '',
//         signature: '',
//         unique_id: '',
//         province: '',
//         city: '',
//         gender: '',
//         school: {
//           name: '',
//           department: null,
//           joinTime: null,
//           education: null,
//           displayType: enums.DISPLAY_TYPE.ALL
//         },
//         avatar_168x168: {
//           url_list: []
//         },
//         avatar_300x300: {
//           url_list: []
//         },
//         cover_url: [
//           {
//             url_list: []
//           }
//         ],
//         white_cover_url: [
//           {
//             url_list: []
//           }
//         ]
//       },
//       friends: resource.users
//     }
//   },
//   getters: {
//     selectFriends() {
//       return this.friends.all.filter((v) => v.select)
//     }
//   },
//   actions: {
//     async init() {
//       const r = await panel()
//       if (r.success) {
//         this.userinfo = Object.assign(this.userinfo, r.data)
//       }
//       const r2 = await friends()
//       if (r2.success) {
//         this.users = r2.data
//       }
//     },
//     setUserinfo(val) {
//       this.userinfo = val
//     },
//     setMaskDialog(val) {
//       this.maskDialog = val.state
//       if (val.mode) {
//         this.maskDialogMode = val.mode
//       }
//     },
//     updateExcludeNames(val) {
//       if (val.type === 'add') {
//         if (!this.excludeNames.find((v) => v === val.value)) {
//           this.excludeNames.push(val.value)
//         }
//       } else {
//         const resIndex = this.excludeNames.findIndex((v) => v === val.value)
//         if (resIndex !== -1) {
//           this.excludeNames.splice(resIndex, 1)
//         }
//       }
//       // console.log('store.excludeNames', store.excludeNames,val)
//     }
//   }
// })
