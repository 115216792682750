export class UserDataProfileModel {
  displayName: string = ''
  email: string = ''
  phoneNumber: string = ''
  photoURL: string = ''
  providerId: string = ''
  uid: string = ''
}

export class UserDataTokenModel {
  accessToken: string = ''
  expirationTime: string = ''
  refreshToken: string = ''
}

export class UserDataModel {
  apiKey: string = ''
  appName: string = ''
  createdAt: string = ''
  email: string = ''
  emailVerified: boolean = false
  isAnonymous: boolean = false
  lastLoginAt: string = ''
  providerData: UserDataProfileModel[] = []
  stsTokenManager: UserDataTokenModel = new UserDataTokenModel()
}
